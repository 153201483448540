import React, { useEffect, useContext } from 'react'
import './order-completed.scss'
import SiteContext from '../context/StoreContext'

const OrderPage = () => {
	const {
		store: {
			checkout: { lineItems },
		},
	} = useContext(SiteContext)
	useEffect(() => {
		if (lineItems.length) {
			localStorage.removeItem('shopify_checkout_id')
		}
	}, [lineItems])
	return (
		<div className="order-container">
			<h1 className="u-h0">Your order is on the way!</h1>
			<div className="line-items-container">
				{lineItems.map((item, i) => {
					const variantImage = item.variant.image ? (
						<img
							src={item.variant.image.src}
							alt={`${item.title} product shot`}
							height="90px"
						/>
					) : null
					return (
						<div key={i} className="d-flex align-center flex-around line-item">
							{variantImage}
							<div className="line-item-right-half">
								<div className="line-item-title tr">
									{item.title}
									{`  `}
									{item.variant.title === !'Default Title'
										? item.variant.title
										: ''}
								</div>
								<div className="d-flex flex-end">
									<div className="quantity">Quantity: {item.quantity}</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>
			<div className="btn-container">
				<a href="/shop">
					<button>Continue Shopping</button>
				</a>
				<a href="/">
					<button>Home</button>
				</a>
			</div>
		</div>
	)
}

export default OrderPage
